<template>
  <component
    v-if="scalePlanTemplate"
    :is="scalePlanTemplate"
    :disabled="!isFieldAvailableForEdit(scope.row, 'scale.plan')"
    :data="scope.row.scale"
    @change="saveElementPropChange(scope.row, 'scale', {plan:$event})"
    @elements-changed="$emit('elements-changed')"
  ></component>
  <div v-else style="color: #b1b1b2;">Выберите тип цели</div>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'element-configurable-scale-plan-table-column',
  mixins: [ElementPropTableColumn],
  components: {ClickToEdit},

  props: {},
  data() {
    return {}
  },
  computed: {
    scalePlanTemplate() {
      if (!this.scope.row?.scale?.template?.type) {
        return null;
      }
      return this.$companyConfiguration.scales.getConfigurableScaleTemplate(this.scope.row.scale.template.type, 'cardPlanView');
    },
  },
  methods: {}
}

</script>

<style>

</style>
